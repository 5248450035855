<template>
    <div class="px-3 py-2" style="direction:rtl">
        <v-form
            ref="form"
            lazy-validation
        >
            <b-sidebar id="pay_gates" width="50rem" style="direction:ltr" right title="طرق الدفع" shadow >
            <v-divider></v-divider>
                <v-row class="m-2">
                    <v-col cols="12">
                        <v-textarea
                            v-model="description"
                            label="طرق الدفع"
                            :rules="textrule"
                        ></v-textarea>
                    </v-col>
                    
                    <v-col cols="3">
                        <b-button type="button" @click="addTerms()" class="btn btn-success btn-sm">إضافة</b-button>
                    </v-col>
                </v-row>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            textrule:[
                 v => !!v || 'هذا الحقل مطلوب',
            ],
            description: '',
        }
    },
    methods: {
        addTerms(){
            if(!this.$refs.form.validate()){
                return false;
            }
            const post = new FormData();
            post.append("type","addTerms");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crmid]",this.$route.params.id);
            post.append("data[description]",this.description);
            post.append("data[type]",'pay_gates');
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                this.getTerms()
            })
        },
        getTerms(){
            const post = new FormData();
            post.append("type","getTerms");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crmid]",this.$route.params.id);
            post.append("data[type]",'pay_gates');
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                this.description = response.data.results.data;
            })
        }
    },
    created() {
        this.getTerms();
       
    },
}
</script>