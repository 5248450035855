<template>
  <div style="margin:0px;">
    <v-row>
      <v-col cols="12" class="text-center">
        <table class="drawTable" style="width:272px;height:243px;margin:auto !important;">
          <tr>
            <td colspan="2">
              <img id="click1_0" :src="`${publicPath}vcar-ac_01.png`" width="87" height="11" alt=""></td>
            <td colspan="2">
              <img id="click1_8" :src="`${publicPath}vcar-ac_02.png`" width="104" height="11" alt=""></td>
            <td colspan="2">
              <img id="click1_16" :src="`${publicPath}vcar-ac_03.png`" width="80" height="11" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="11" alt=""></td>
          </tr>
          <tr>
            <td colspan="2" rowspan="2">
              <img id="click1_1" :src="`${publicPath}vcar-ac_04.png`" width="87" height="64" alt=""></td>
            <td>
              <img id="click1_9" :src="`${publicPath}vcar-ac_05.png`" width="49" height="17" alt=""></td>
            <td>
              <img id="click1_10" :src="`${publicPath}vcar-ac_06.png`" width="55" height="17" alt=""></td>
            <td colspan="2" rowspan="2">
              <img id="click1_17" :src="`${publicPath}vcar-ac_07.png`" width="80" height="64" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="17" alt=""></td>
          </tr>
          <tr>
            <td colspan="2">
              <img id="click1_11" @click="changeMe(11,$event)" :src="`${publicPath}vcar-ac_08.png`" width="104" height="47" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="47" alt=""></td>
          </tr>
          <tr>
            <td rowspan="2">
              <img id="click1_2" :src="`${publicPath}vcar-ac_09.png`" width="50" height="64" alt=""></td>
            <td rowspan="2">
              <img id="click1_3" :src="`${publicPath}vcar-ac_10.png`" width="37" height="64" alt=""></td>
            <td colspan="2">
              <img id="click1_12" :src="`${publicPath}vcar-ac_11.png`" width="104" height="42" alt=""></td>
            <td rowspan="3">
              <img id="click1_18" :src="`${publicPath}vcar-ac_12.png`" width="33" height="66" alt=""></td>
            <td rowspan="2">
              <img id="click1_19" :src="`${publicPath}vcar-ac_13.png`" width="47" height="64" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="42" alt=""></td>
          </tr>
          <tr>
            <td colspan="2" rowspan="3">
              <img id="click1_13" :src="`${publicPath}vcar-ac_14.png`" width="104" height="57" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="22" alt=""></td>
          </tr>
          <tr>
            <td rowspan="3">
              <img id="click1_4" :src="`${publicPath}vcar-ac_15.png`" width="50" height="62" alt=""></td>
            <td rowspan="3">
              <img id="click1_5" :src="`${publicPath}vcar-ac_16.png`" width="37" height="62" alt=""></td>
            <td rowspan="3">
              <img id="click1_20" :src="`${publicPath}vcar-ac_17.png`" width="47" height="62" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="2" alt=""></td>
          </tr>
          <tr>
            <td rowspan="2">
              <img id="click1_21" :src="`${publicPath}vcar-ac_18.png`" width="33" height="60" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="33" alt=""></td>
          </tr>
          <tr>
            <td colspan="2">
              <img id="click1_14" :src="`${publicPath}vcar-ac_19.png`" width="104" height="27" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="27" alt=""></td>
          </tr>
          <tr>
            <td colspan="2">
              <img id="click1_6" :src="`${publicPath}vcar-ac_20.png`" width="87" height="27" alt=""></td>
            <td colspan="2" rowspan="2">
              <img id="click1_15" :src="`${publicPath}vcar-ac_21.png`" width="104" height="42" alt=""></td>
            <td colspan="2">
              <img id="click1_22" :src="`${publicPath}vcar-ac_22.png`" width="80" height="27" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="27" alt=""></td>
          </tr>
          <tr>
            <td colspan="2">
              <img id="click1_7" :src="`${publicPath}vcar-ac_23.png`" width="87" height="15" alt=""></td>
            <td colspan="2">
              <img id="click1_23" :src="`${publicPath}vcar-ac_24.png`" width="80" height="15" alt=""></td>
            <td>
              <img :src="`${publicPath}spacer.gif`" width="1" height="15" alt=""></td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  components: {  },
  name: 'CVAdditionalRow',
  data () {
    return {
      publicPath: window.location.origin+'/vcar/',
      publicPathv: window.location.origin,
      currSrc: [
          '/vcar/vcar-ac_01.png',
          '/vcar/vcar-ac_04.png',
          '/vcar/vcar-ac_09.png',
          '/vcar/vcar-ac_10.png',
          '/vcar/vcar-ac_15.png',
          '/vcar/vcar-ac_16.png',
          '/vcar/vcar-ac_20.png',
          '/vcar/vcar-ac_23.png',
          '/vcar/vcar-ac_02.png',
          '/vcar/vcar-ac_05.png',
          '/vcar/vcar-ac_06.png',
          '/vcar/vcar-ac_08.png',
          '/vcar/vcar-ac_11.png',
          '/vcar/vcar-ac_14.png',
          '/vcar/vcar-ac_19.png',
          '/vcar/vcar-ac_21.png',
          '/vcar/vcar-ac_03.png',
          '/vcar/vcar-ac_07.png',
          '/vcar/vcar-ac_12.png',
          '/vcar/vcar-ac_13.png',
          '/vcar/vcar-ac_17.png',
          '/vcar/vcar-ac_18.png',
          '/vcar/vcar-ac_22.png',
          '/vcar/vcar-ac_24.png',
      ],
      selSrc: [
        '/vcar/vcar-ac-sel_01.png',
        '/vcar/vcar-ac-sel_04.png',
        '/vcar/vcar-ac-sel_09.png',
        '/vcar/vcar-ac-sel_10.png',
        '/vcar/vcar-ac-sel_15.png',
        '/vcar/vcar-ac-sel_16.png',
        '/vcar/vcar-ac-sel_20.png',
        '/vcar/vcar-ac-sel_23.png',
        '/vcar/vcar-ac-sel_02.png',
        '/vcar/vcar-ac-sel_05.png',
        '/vcar/vcar-ac-sel_06.png',
        '/vcar/vcar-ac-sel_08.png',
        '/vcar/vcar-ac-sel_11.png',
        '/vcar/vcar-ac-sel_14.png',
        '/vcar/vcar-ac-sel_19.png',
        '/vcar/vcar-ac-sel_21.png',
        '/vcar/vcar-ac-sel_03.png',
        '/vcar/vcar-ac-sel_07.png',
        '/vcar/vcar-ac-sel_12.png',
        '/vcar/vcar-ac-sel_13.png',
        '/vcar/vcar-ac-sel_17.png',
        '/vcar/vcar-ac-sel_18.png',
        '/vcar/vcar-ac-sel_22.png',
        '/vcar/vcar-ac-sel_24.png',
      ],
      clicked:[],
      cardid: 0,
    }
  },
  props:{
    getData: Function,
    clickedp: [],
  },
  mounted(){
    //this.cardid = this.$route.params.cardid;
    
  },
  created(){
   setTimeout(() => {
     this.getCard();
   },1000);
   setTimeout(() => {
     //this.changeMe();
   },3000)
  },
  methods:{
    getCard(){
      let tcardid = this.$route.params.cardid;
      if(typeof tcardid === 'undefined'){
        return false;
      }
      this.cardid = tcardid;
      let post = new FormData();
      post.append('type' , 'getCards');
      const auth = this.$cookies.get(this.$COOKIEPhase);
      post.append('auth',auth);
      post.append('data[cardid]',this.cardid)
      axios.post(
        this.$store.state.SAMCOTEC.r_path,
        post
      ).then((response) => {
        const res = response.data;
        const cl = res.results.data[0].clicked;
        const clt = cl.split(',');
        for(let i = 0;i<clt.length;i++){ 
          this.clicked.push(parseInt(clt[i])); 
        }
      
      }).then(()=>{
        for(let i = 0;i< this.clicked.length;i++){
          document.getElementById('click1_'+this.clicked[i]).src = window.location.origin+this.selSrc[this.clicked[i]];
        }
        
      })
    },
    
    doSelect(){
      
      for(let i = 0;i< this.clicked.length;i++){
        document.getElementById('click_'+this.clicked[i]).src = window.location.origin+this.selSrc[this.clicked[i]];
      }
      //this.getData(this.clicked);
    },
    
  }
}
</script>
<style>

.drawTable{
  direction: ltr !important;
  padding:0 !important;
  margin:0 !important;
  border-spacing: 0px !important;
  border: none !important;
}
.drawTable td{
  padding:0 !important;
  margin:0 !important;
  border: none !important;
}
</style>