<template>
    <div class="pa-2 m-2">
        <bread-crumbs :items="items"></bread-crumbs>
        <v-row class="m-3">
            <div class="text-end">
                <b-button @click="generate()" class="btn-sm btn btn-success">إنشاء ترخيص</b-button>
            </div>
            <v-data-table
                dense
                :headers="licenseHead"
                :items="LicenseRows"
                class="elevation-1 m-1"
                :items-per-page="10"
                :page.sync="notepage"
                width="100%"
                id="MainTable"
                hide-default-footer
                @page-count="pageCount = $event"
                fixed-header
            ></v-data-table>
        </v-row>
    </div>
</template>
<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
export default{
  components: { breadCrumbs },
    name: 'Licese-Generator',
    data() {
        return {
            items: [
                // {
                //     text: this.$store.state.hometitle,
                //     disabled: false,
                //     to: '/home',
                // },
                {
                    text: 'اصدار التراخيص',
                    disabled: true,
                    to: '/Licenses',
                },
            ],
            licenseHead:[
                {
                    text: 'رقم الترخيص',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'licenseid',
                    style: "text-align:center;direction:ltr"
                },
                {
                    text: 'تاريخ الربط',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'issue_date',
                    style: "text-align:center;direction:ltr"
                },
                {
                    text: 'تاريخ التفعيل',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'activiated_date',
                    style: "text-align:center;direction:ltr"
                },
                {
                    text: 'اسم المندوب',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'agent_name',
                    style: "text-align:center;"
                },
                {
                    text: 'اسم المنشأة',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'company_name',
                    style: "text-align:center;"
                },
                {
                    text: 'الرقم الضريبي',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'vatid',
                    style: "text-align:center;"
                },
                {
                    text: 'الجوال',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'mobile',
                    style: "text-align:center;"
                },
                {
                    text: 'رقم الطلب',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'crmid',
                    style: "text-align:center;"
                },
                {
                    text: 'تاريخ الاضافة',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'created_date',
                    style: "text-align:center;"
                },
            ],
            LicenseRows:[
                
            ],
            notepage:0,
            pageCount:10

        }
    },
    methods: {
        generate() {
            const post = new FormData();
            post.append("type","genLicense");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]" , 'all');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((res) => {
                
                this.getLicense()
            })
        },
        getLicense(){
            const post = new FormData();
            post.append("type","getLicense");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]" , 'all');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((res) => {
                this.LicenseRows = res.data.results.data;
            })
        }
    },
    created() {
        this.getLicense();
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>