<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
            <b-sidebar backdrop id="add_smstemp" width="50rem" style="direction:ltr" right title="إضافة رسالة SMS" shadow >
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="12" md="4" sm="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="smstime"
                            :items="smstimes"
                            :rule="thisRull"
                            label="وقت الارسال"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="smstype"
                            :rule="thisRull"
                            :items="smstypes"
                            label="نوع الرسالة"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-textarea
                            v-model="message"
                            label="نص الرسالة"
                            :rule="thisRull"
                            value=""
                            required
                            ></v-textarea>
                    </v-col>
                </v-row>

                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addSMSTem()" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    props: ['sms'],
    data() {
        return {
            thisRull: [
                v => !!v || 'هذا الحقل مطلوب'
            ],
            message: '',
            smstime:1,
            smstimes:[
                {
                    text: 'عند اضافة العميل',
                    value: 1,
                },
                {
                    text: 'عند اضافة عرض سعر',
                    value: 2,
                },
                {
                    text: 'عند اصدار دفعة',
                    value: 3,
                },
                {
                    text: 'عند اصدار فاتورة',
                    value: 4,
                },
                {
                    text: 'عند اصدار ترخيص',
                    value: 5,
                },
                {
                    text: 'عند الغاء الطلب',
                    value: 6,
                },
            ],
            smstype:1,
            smstypes:[
                {
                    text: 'رسالة نصية',
                    value: 1,
                },
                {
                    text: 'رسالة واتس اب',
                    value: 2,
                },
            ]
        }
    },
    methods: {
        addSMSTem() {
            if(!this.$refs.form.validate()){
                return false;
            }
            const post = new FormData();
            post.append("type" , "addSMSTemp");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[msg_type]",this.smstype);
            post.append("data[message]",this.message);
            post.append("data[sent_at]",this.smstime);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((res) => {
                this.message = '';
                this.$parent.getAllSMS();
            })
        }
    },
    created() {
        // let x = setInterval(() => {
        //     // console.log(this.sms);
        //     if(this.sms != null || this.sms != ''){
        //         this.message = this.sms.message;
        //         this.smstime = this.sms.sent_at;
        //         this.smstype = this.sms.msg_type;
                
        //     }
        // },1000)
    },
}
</script>