<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="add_product" aria-labelledby="sidebar-no-header-title" no-header backdrop width="700px" style="direction:ltr" right title="اضافة منتج" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>اضافة منتج</span>
        </div>
        <div @click="hide" id="hidecrmprod" style="text-align:center;width:100px;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="12" md="3" sm="12">
                        <v-text-field
                            label="رقم الصنف"
                            v-model="product.item_code"
                            @change="changeItemCode()"
                            >{{ product.name }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="9" sm="12">
                        <v-combobox
                            v-model="product.name"
                            :items="productsname"
                            small-chips
                            label="اسم المنتج"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <v-text-field
                            label="الكمية"
                            v-model="product.qty"
                            type="number"
                            @change="changePtype()"
                            >{{ product.name }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            label="السعر"
                            v-model="product.price"
                            type="number"
                            @change="changePtype()"
                            >{{ product.name }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="5" sm="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="product.vattype"
                            :items="vattype"
                            label="نوع الضريبة"
                            @change="changePtype()"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            label="الاجمالي بدون ضريبة"
                            v-model="product.total"
                            type="number"
                            readonly
                            >{{ product.total }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            label="الضريبة"
                            v-model="product.vat"
                            type="number"
                            readonly
                            >{{ product.vat }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            label="الاجمالي مع الضريبة"
                            v-model="product.vat"
                            type="number"
                            readonly
                            >{{ product.vat }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="product.type"
                            :items="ptypes"
                            label="نوع المنتج"
                            @change="changePtype()"
                        ></v-select>
                    </v-col>
                </v-row>
    </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addProd()" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            crmid: 0,
            productsname: [],
            product: {
                item_code: '',
                name: '',
                qty: 1,
                price: 0,
                vattype: 3,
                total: 0,
                vat: 0,
                ftotal: 0,
                type: 1
            },
            vattype: [
                {
                    text: 'السعر شامل الضريبة',
                    value: 3,
                },
                {
                    text: 'السعر غير شامل الضريبة',
                    value: 2,
                },
            ],
            ptypes: [
                {
                    text: 'مخزون',
                    value: 1,
                },
                {
                    text: 'صنف خدمي',
                    value: 2,
                },
            ],
        }
    },
    methods: {
        changeItemCode() {
            if(this.product.item_code == ''){
                return false;
            }
            const post = new FormData();
            post.append("type" , "getItemBycode"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.product.item_code)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data.results;
                if(res.length > 0){
                    this.product.name = res[0].name;
                    this.product.price = res[0].price;
                }
            }).then(() => {
                this.changePtype();
            })
        },
        getProducts(){
            const post = new FormData();
            post.append("type","getallproducts");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]","1");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                if(res.results.data.length > 0){
                    for(let i=0;i<res.results.data.length;i++){
                        this.productsname.push(res.results.data[i])
                    }
                }
            });
        },
        changePtype() {
            const price = +this.product.price * +this.product.qty;
            const e = this.$calcVat(price,this.product.vattype);
            this.product.total = this.$RoundNum(e.tot);
            this.product.vat = this.$RoundNum(e.vat);            
            this.product.ftotal = this.$RoundNum(e.ftot);            
        },
        addProd(){
            this.$parent.products.push(this.product);
            const post = new FormData();
            post.append("type" , "addProductToCRM");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crmid]",this.crmid);
            post.append("data[name]",this.product.name);
            post.append("data[qty]",this.product.qty);
            post.append("data[price]",this.product.price);
            post.append("data[vattype]",this.product.vattype);
            post.append("data[total]",this.product.total);
            post.append("data[vat]",this.product.vat);
            post.append("data[ftotal]",this.product.ftotal);
            post.append("data[type]",this.product.type);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                
            })
            this.product = {
                name: '',
                qty: 1,
                price: 0,
                vattype: 3,
                total: 0,
                vat: 0,
                ftotal: 0
            }
            this.$parent.getOrder();
            document.getElementById('hidecrmprod').click();
        }
    },
    created() {
        this.getProducts();
    },
}
</script>
        
 