<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="add_crmnote" aria-labelledby="sidebar-no-header-title" no-header backdrop width="700px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>إضافة ملاحظة</span>
        </div>
        <div @click="hide" id="hideNotes" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="12" md="12" sm="12">
                         <label>تنبيه لـ</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="notify"
                            :options="notifying"
                            label="تنبيه لـ "
                        ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <label>نص الملاحظة</label>
                        <b-form-textarea class="inborder"
                            v-model="message"
                            label="نص الملاحظة"
                            value=""
                            required
                            ></b-form-textarea>
                    </v-col>
                </v-row>
    </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addNote()" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            message: '',
            notify:1,
            orderid: 0,
            notifying:[
                {
                    text: 'لا ترسل تنبيهات',
                    value: 1,
                },
                {
                    text: 'تنبيه للكول سنتر',
                    value: 2,
                },
                {
                    text: 'تنبيه للعميل',
                    value: 3,
                },
                {
                    text: 'تنبيه للادارة',
                    value: 4,
                },
                {
                    text: 'تنبيه للمحاسب',
                    value: 5,
                },
            ]
        }
    },
    methods: {
        addNote(){
            if(this.message == ''){
                return false;
            }
            const post = new FormData();
            post.append("type" , "addNoteToCRM");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crmid]",this.orderid);
            post.append("data[message]",this.message);
            post.append("data[notify]",this.notify);
            post.append("data[type]",1);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                let message = 'تمت الاضافة بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getOrder();
                document.getElementById('hideNotes').click();
            });
            
            this.message = '';
        }
    },
    created() {
        //this.getNotes();
    },
}
</script>