<template>
    <div class="px-3 py-2">
        <v-form
            ref="formtec"
            lazy-validation
        >
            <b-sidebar id="change_crmevery" aria-labelledby="sidebar-no-header-title" no-header backdrop width="80rem" style="direction:ltr" right title="اضافة عميل" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>إضافة عميل</span>
                        </div>
                        <div @click="hide" id="closethis" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>اغلاق</span>
                    </div>
                </div>
            <v-divider></v-divider>
                <div class="px-3 py-2" style="direction:rtl">
                    <v-row>
                        <v-col cols="12" md="3" sm="12">
                            <label>رقم الجوال</label>
                            <b-form-input class="inborder"
                                label="رقم الجوال"
                                v-model="customers.mobile"
                                @change="getName()"
                                type="number"
                                :rules="mobileRules"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>جوال اضافي</label>
                            <b-form-input class="inborder"
                                label="جوال اضافي"
                                v-model="customers.mobile1"
                                type="number"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>اسم العميل</label>
                            <b-form-input class="inborder"
                                label="اسم العميل"
                                v-model="customers.full_name"
                                :rules="nameRules"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>البريد الالكتروني</label>
                            <b-form-input class="inborder"
                                label="البريد الالكتروني"
                                v-model="customers.email"
                                type="email"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <b-form-input class="inborder"
                                label="اسم المنشأة"
                                v-model="customers.company"
                                type="email"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>الاسم التجاري</label>
                            <b-form-input class="inborder"
                                label="الاسم التجاري"
                                v-model="customers.trademark"
                                type="email"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>رقم السجل</label>
                            <b-form-input class="inborder"
                                label="رقم السجل"
                                v-model="customers.crt_number"
                                type="email"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>الرقم الضريبي</label>
                            <b-form-input class="inborder"
                                label="الرقم الضريبي"
                                v-model="customers.vatid"
                                type="email"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>النشاط</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="activity"
                                :options="activities"
                                label="النشاط"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>نوع الطلب</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="systemtype"
                                :options="systemtypes"
                                label="نوع الطلب"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>توفير أجهزة</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="need_computers"
                                :options="yesNo"
                                label="توفير أجهزة"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>حالة الطلب</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="status"
                                :options="orderStatus"
                                label="حالة الطلب"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>عدد النقاط</label>
                            <b-form-input class="inborder"
                                v-model="posnumebr"
                                type="number"
                                label="عدد النقاط"
                            ></b-form-input>
                        </v-col>
                        
                        <v-col cols="12" md="6" sm="12">
                            <label>العنوان</label>
                            <b-form-input class="inborder"
                                v-model="customers.address"
                                label="العنوان"
                            ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>المندوب</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="agentid"
                                :options="agents"
                                label="المندوب"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </div>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                        <b-button type="button" @click="addCRM();" variant="success" class="ma-2" style="width:100px;">تعديل</b-button>
                    </div>
            </template>
            </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios';
import {SnotifyPosition} from "vue-snotify"
export default{
    data() {
        return {
            fullrows: [],
            agentid: 0,
            full_price: 0,
            agents: [],
            activity: 0,
            posnumebr: 0,
            status: 0,
            systemtype: 0,
            need_computers: 0,
            mobileRules: [
                v => !!v || 'رقم الجوال مطلوب',
                v => (v && v.length <= 10) || 'رقم الجوال يجب ان يكون عشر ارقام',
            ],
            nameRules: [
                v => !!v || 'اسم العميل مطلوب',
            ],
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        activities: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[1].length;i++){
                let xx = {
                    text: this.$parent.lang.langname == "ar" ? this.fullrows[1][i].namear : this.fullrows[1].nameen,
                    value: this.fullrows[1][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        yesNo: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[0].length;i++){
                let xx = {
                    text: this.$parent.lang.langname == "ar" ? this.fullrows[0][i].namear : this.fullrows[0].nameen,
                    value: this.fullrows[0][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        systemtypes: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[2].length;i++){
                let xx = {
                    text: this.$parent.lang.langname == "ar" ? this.fullrows[2][i].namear : this.fullrows[2].nameen,
                    value: this.fullrows[2][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        orderStatus: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[3].length;i++){
                let xx = {
                    text: this.$parent.lang.langname == "ar" ? this.fullrows[3][i].namear : this.fullrows[3].nameen,
                    value: this.fullrows[3][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        customers:function(){
            return {
                mobile: this.$parent.crminfo.mobile,
                mobile1: this.$parent.crminfo.mobile1,
                full_name: this.$parent.crminfo.full_name,
                email: this.$parent.crminfo.email,
                company: this.$parent.crminfo.company,
                vatid: this.$parent.crminfo.vatid,
                id: 0,
                address: this.$parent.crminfo.address,
                trademark:this.$parent.crminfo.trademark,
                crt_number: this.$parent.crminfo.crt_number,
            }
        },
    },
    created() {
        this.getAgents();
        this.getAsettings();
        //setTimeout(() => {this.checkMe();},5000)
    },
    methods: {
        checkMe(){
            this.activity = this.$parent.crminfo.activity;
            this.posnumebr = this.$parent.crminfo.posnumebr;
            this.status = this.$parent.crminfo.status;
            this.systemtype = this.$parent.crminfo.systemtype;
            this.need_computers = this.$parent.crminfo.need_computers;
            this.agentid = this.$parent.crminfo.agentid;
        },
        getAsettings(){
            const post = new FormData();
            post.append("type","getCRMSettigns");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'1');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log("----",res.results.data);
                this.fullrows = res.results.data;
            });
        },
        addCRM(){
            if(!this.$refs.formtec.validate()){
                return false;
            }
            const post = new FormData();
            post.append('type','updateCRM');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customers)){
                post.append('data[customers]['+key+']]',value);
            }
            post.append("data[agentid]",this.agentid);
            post.append("data[activity]",this.activity);
            post.append("data[posnumebr]",this.posnumebr);
            post.append("data[status]",this.status);
            post.append("data[systemtype]",this.systemtype);
            post.append("data[need_computers]",this.need_computers);
            post.append("data[id]",this.$route.params.id);
            post.append("data[id]",this.$route.params.id);
            axios.post(
                this.$SAMCOTEC.r_path, post 
            ).then((response) => {
                // console.log("its added",response.data);
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                 
                let message = 'تمت تعديل العميل بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                let custo = response.data.results.data.deal;
                custo.id = response.data.results.data.dID;
                this.$parent.getOrder();
                document.getElementById('closethis').click();
                
            })
        },
        getName(){
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customers.mobile);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res.error.number == 200){
                        this.customers.id = res.results.data['id'];
                        this.customers.full_name = res.results.data['full_name'];
                    }
                }
            )
        },
        getAgents(){
            const post = new FormData();
            post.append("type","getAgents");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.agents = res.results.data.agents;
                this.agentid = res.results.data.myID;
            });
        }
    },
}
</script>