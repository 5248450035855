<template>
    <div class="px-3 py-2">
        <v-form
            ref="formcc"
            lazy-validation 
        >
        <b-sidebar backdrop id="add_crmtask" width="700px" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="اضافة مهمة" shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>اضافة مهمة</span>
                </div>
                <div @click="hide" id="hidemetask" style="text-align:center;width:100px;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>اغلاق</span>
            </div>
            </div>
            <v-divider></v-divider>
            <v-row class="m-2" style="direction:rtl">
                <v-col cols="12" md="12" sm="12">
                    <label>المهمة لـ</label>
                    <b-input-group>
                    <b-form-select class="selborder"
                        v-model="response"
                        :options="users"
                        label="المهمة لـ"
                        :rules="nameRules"
                        required 
                    ></b-form-select>
                        <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-addon>
                    </b-input-group>
                    <v-col cols="12" md="12" sm="12">
                        <label>تفاصيل المهمة</label>
                        <b-form-textarea class="inborder"
                            v-model="message"
                            label="تفاصيل المهمة"
                            value=""
                            :rules="nameRules"
                            required
                            ></b-form-textarea>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <label>تفاصيل المهمة</label>
                            <b-form-input class="inborder"
                                v-model="date"
                                label="الموعد النهائي"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></b-form-input>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                </v-col>
            </v-row>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addTask()" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            response: 0,
            users:[],
            message: '',
             nameRules: [
                v => !!v || 'هذا الحقل مطلوب',
            ],
            orderid: 0
        }
    },
    methods: {
        getAgents(){
            const post = new FormData();
            post.append("type","getAgents");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.users = res.results.data.agents;
                this.agentid = res.results.data.myID;
            });

        },
        addTask(){
            if(!this.$refs.formcc.validate()){
                return false;
            }
            if(this.response == ''){
                return false;
            }
            if(this.message == ''){
                return false;
            }
            const post = new FormData();
            post.append("type","addTask");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[orderid]",this.orderid);
            post.append("data[response]",this.response);
            post.append("data[message]",this.message);
            post.append("data[deadline]",this.date);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((resposne) => {
                const res = resposne.data;
                
                this.$parent.getOrder();
                this.response = 0;
                this.message = '';
                document.getElementById('hidemetask').click();
            })
        }
    },
    created() {
        this.getAgents();
    },
}
</script>